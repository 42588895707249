
import BackgroundImage from "../../assets/svg/theGroup/digitalSolution.jpg"
import stats from "../../assets/svg/theGroup/stats.svg"
import avatar from "../../assets/svg/theGroup/avatar.svg"
export default function DigitalSolutions() {
    return (

        <div className='py-16 flex justify-center' style={{ backgroundImage: `url(${BackgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center', }}>

            <div className='lg:w-8/12 md:w-10/12 w-full py-16 md:px-0 px-4 flex justify-end'>
                <div className='lg:w-1/2 w-full'>
                    <p className='text-[45px] text-white pb-6'>
                        Digital Solutions
                    </p>
                    <p className='text-white font-extralight text-lg'>
                        We are a strategic partner in the US system integration sector. We are continuously expanding, thanks to our broad portfolio of solutions and our specialised technological expertise with a team of 50+ and 800 personal technology certifications, we have seen revenues of $2M in the digital solutions sector alone…

                    </p>
                    <div className='grid md:grid-cols-2 grid-cols-1 gap-5 mt-10'>
                        <div className='bg-neon p-5 space-y-5'>
                            <p className='text-center text-white text-2xl font-bold text-[rgb(0, 226, 192)]'>
                                HIGHLIGHTS
                            </p>
                            <div className='flex space-x-5 text-white'>
                                <img src={avatar} className='w-12' />
                                <div>
                                    <p className='text-[25px]'>
                                    80+
                                    </p>
                                    <p className='text-sm'>
                                        Customers
                                    </p>
                                </div>
                            </div>
                            <div className='flex space-x-5 text-white'>
                                <img src={stats} className='w-12' />
                                <div>
                                    <p className='text-[25px]'>
                                        $2M
                                    </p>
                                    <p className='text-sm'>
                                        Turnover in 2022
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='bg-neon p-5 space-y-5'>
                            <p className='text-left text-white text-2xl font-bold text-[rgb(0, 226, 192)]'>
                                PORTFOLIO
                            </p>
                            <p className='text-white text-lg pr-12 pb-12'>
                                Data Solutions
                                Cloud <br></br>
                                Systems Wired
                                Fixed Networks
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
