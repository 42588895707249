import { useEffect, useRef, useState } from "react";
import CountUp from 'react-countup';
import image from "../../assets/About/AboutFigur.jpg"

export default function Figures() {

    const [isVisible, setIsVisible] = useState(false);
    const whyUsRef = useRef(null);

    const handleScroll = () => {
        const top = whyUsRef.current.getBoundingClientRect().top;
        const isVisible = top < window.innerHeight;
        setIsVisible(isVisible);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className='pb-16 bg-[#f7f7f7]'>
            <div ref={whyUsRef} className='grid lg:grid-cols-9  grid-cols-1 items-center'>
                <div className='w-auto hidden lg:block col-span-4'>
                    <img src={image} alt="" className="h-[100%]" />
                </div>
                <div className='col-span-5 pl-4 md:pr-20 pr-0 space-y-10'>
                    <h1 className='text-heading text-3xl font-bold pb-12'>
                        Callcom Global In Figures
                    </h1>
                    <div>
                        <div className='md:grid flex flex-col gap-10 items-center md:items-start md:text-left text-center border-b-2 border-b-gray-300  md:grid-cols-2 grid-cols-1  pb-10 mb-10'>
                            <div className='text-black flex flex-col gap-4'>
                                <p className='textdec text-5xl md:text-7xl xl:text-5xl font-bold'>
                                    <CountUp
                                        start={isVisible ? 0 : null}
                                        end={isVisible ? parseInt(100) : null}
                                        duration={2.75}
                                    />+
                                </p>
                                <p className="text-2xl md:text-2xl font-light">
                                    Customers
                                </p>
                            </div>
                            <div className='text-black flex flex-col gap-4'>
                                <p className='textdec text-5xl md:text-7xl xl:text-5xl font-bold'>
                                    <CountUp
                                        start={isVisible ? 0 : null}
                                        end={isVisible ? parseInt(1) : null}
                                        duration={2.75}
                                    />Billion
                                </p>
                                <p className="text-lg md:text-2xl font-light">
                                    International Voice Minutes in 2023
                                </p>
                            </div>
                        </div>
                        <div className='md:grid flex flex-col gap-10 items-center md:items-start border-b-2 border-b-gray-300 md:text-left text-center  md:grid-cols-2 grid-cols-1   pb-10 mb-10'>
                            <div className='text-black flex flex-col gap-4'>
                                <p className='textdec text-5xl md:text-7xl xl:text-5xl font-bold'>
                                    $<CountUp
                                        start={isVisible ? 0 : null}
                                        end={isVisible ? parseInt(45) : null}
                                        duration={2.75}
                                    />M
                                </p>
                                <p className="text-2xl md:text-2xl font-light">
                                    turnover in 2022
                                </p>
                            </div>
                            <div className='text-black flex flex-col gap-4'>
                                <p className='textdec text-5xl md:text-7xl xl:text-5xl font-bold'>
                                    <CountUp
                                        start={isVisible ? 0 : null}
                                        end={isVisible ? parseInt(1) : null}
                                        duration={2.75}
                                    />M
                                </p>
                                <p className="text-2xl md:text-2xl font-light">
                                    Interventions managed each year
                                </p>
                            </div>
                        </div>
                        {/* <div className='md:grid flex flex-col gap-10 items-center md:items-start border-b-2 border-b-gray-300 md:text-left text-center  md:grid-cols-2 grid-cols-1   pb-10 mb-10'>
                            <div className='text-black flex flex-col gap-4'>
                                <p className='textdec text-5xl md:text-7xl xl:text-5xl font-bold'>
                                    <CountUp
                                        start={isVisible ? 0 : null}
                                        end={isVisible ? parseInt(800) : null}
                                        duration={2.75}
                                    />+
                                </p>
                                <p className="text-2xl md:text-2xl font-light">
                                    ICT specialist certifications
                                </p>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}
