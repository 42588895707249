
export default function SectionOne() {
    return (
        <div className='flex justify-center'>
            <div className='lg:w-10/12 md:w-10/12 w-full py-16 md:pb-20 lg:pb-64 md:px-0 px-4'>
                <h1 className='text-heading font-bold text-lg md:text-xl'>
                Our Sustainable Vision
                </h1>
                <p className='lg:text-4xl w-11/12 md:w-10/12 lg:w-8/12 font-bold md:text-3xl text-2xl text-black pt-12'>
                    Thinking about society and the planet and driving business so that it is part of the solution to the challenges of mankind
                </p>
                <div className="w-[10%] h-2 my-10 bg-gray-700 rounded-full"></div>
                <div className="font-extralight grid grid-cols-1 lg:grid-cols-2 gap-10 text-black pt-8">
                    <p className="text-xl md:text-2xl font-light text-black">
                    The Callcom Global Group believes that correct, transparent and responsible conduct is a prerequisite for sustainable business development aimed at creating and protecting the value chain for all stakeholders involved.
                    </p>
                    <p className="text-xl md:text-2xl font-light text-black">Growth and sustainability are, in fact, the pillars of the Group, whose development strategy is based on the conviction that ethics and profit should not be at odds, as there can be no long-term economic development divorced from social and environmental development.</p>
                </div>
            </div>
        </div>
    )
}
