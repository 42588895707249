
import { MainLayout } from '../layouts'
import ProffesionaImage from "../assets/Banners/Proffesional.jpg"

import Life from "../assets/Careers/1.png"
import Life2 from "../assets/Careers/2.png"


import Section1 from '../components/Proffesional/Section1'
import Section2 from '../components/Proffesional/Section2'
import ScrollToTop from '../components/scrolltop'

export default function ProffesionalLife() {
  return (
    <MainLayout pageName="Careers" Banner={ProffesionaImage}>
      <ScrollToTop/>

      <Section1 />
      <div className='hidden xl:block relative'>
        {/* <div className="w-[100%] h-[600px] absolute z-[9] mb-[580px]" style={{ background: "linear-gradient(rgb(255, 255, 255) 120px, rgba(255, 255, 255, 0) 80%)" }}>
        </div> */}
        <div className='h-[100vh] relative' style={{ backgroundImage: `url(${Life})`, backgroundSize: 'cover', backgroundPosition: 'center', }}>
          <div className='absolute top-0 h-full bg-gradient-to-b from-white to-black'>
          </div>
        </div>
      </div>

      <Section2 />

      <div className='hidden xl:block relative'>
        {/* <div className="w-[100%] h-[663px] absolute z-[9] mb-[580px]" style={{ background: "linear-gradient(rgb(255, 255, 255) 120px, rgba(255, 255, 255, 0) 80%)" }}>
        </div> */}
        <div className='h-[100vh] relative' style={{ backgroundImage: `url(${Life2})`, backgroundSize: 'cover', backgroundPosition: 'center', }}>
          <div className='absolute top-0 h-full bg-gradient-to-b from-white to-black'>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}
