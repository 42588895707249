import Case3 from "../assets/Case/Case3.jpg"
import Case4 from "../assets/Case/Case4.jpg"
import Case5 from "../assets/Case/Case5.jpg"
import Case8 from "../assets/Case/Case8.jpg"

export const BlogData=[
    {
        ID:1,
        Heading:"Network Transformation: PSTN/ATM decommissioning",
        Customer:"Charter Communications",
        meta:"Network modernisation and structural transformation projects",
        Image:Case3,
        Detail:[
            {
                Heading:"The Scenario",
                Paras:[
                    "Technological evolution, decreasing revenues from traditional services, competition pressure and the increasingly strong market presence of the Over The Top are pushing all Telecommunications Operators towards a continuous process of modernisation and structural transformation of their networks (“network transformation”).",
                    "This evolution requires a rethinking of network infrastructures with the aim of drastically reducing operating costs and making the introduction of innovative services faster and more flexible",
                    "Transforming a network into operation, especially if it consists of several layers and many interconnected technological generations, is a very complex activity.",
                    "The implementation of such transformations, especially with approaches starting from the lowest network levels, implies a great impact on operations and territorial organisation."
                ]
            },
            {
                Heading:"The Challenge",
                Paras:[
                    "The Network Decommissioning project of the customer arose from the need to reduce costs resulting from the space used and energy consumption with the aim of rationalising and reorganising the centres (SdL, SGU).",
                ],
                Points:{
                    Heading:"This was achieved along two lines:",
                    Point:[
                        "PSTN: compaction of legacy RTG users and eventual migration of PSTN services to VoIP platforms with elimination of obsolete platforms (S12, UT100, AXE)",
                        "ATM: migration and compaction of TGU DSLAM ATM to IP platform"
                    ]
                }
            },
            {
                Heading:"The Solution",
                Paras:[
                    "In tackling Network Decommissioning projects, Callcom Global was able to integrate knowledge of old-generation (“legacy”) networks and equipment, expertise on all technological layers and delivery capabilities.",
                ],
                Points:{
                    Heading:"The execution of this project required in-depth knowledge and excellent control of the customer’s operational dynamics, therefore guaranteeing:",
                    Point:[
                        "Design service capable of interacting correctly with all the different databases involved ",
                        "definition of innovative plant solutions to improve the speed of migration execution, thus minimising inefficiencies on end users",
                        "massive territorial interventions in the power plant (with peaks of up to 3,000 permutations/day)",
                        "maintenance supervision of impacted lines (post-compaction and post-migration assurance) with challenging SLAs.",
                    ]
                }
            }
        ],
        TechPoints:[
            "Network decommissioning",
            "Network consolidation",
            "IoT monitoring"
        ]
    },
    {
        ID:2,
        Heading:"Multi-Vendor Spare Parts Management",
        Customer:"Globe",
        meta:"Spare parts management with logistics services and repairs",
        Image:Case4,
        Detail:[
            {
                Heading:"The Scenario",
                Paras:[
                    "Globe is one of the largest telecommunications operators in North America, with an extensive and widespread network consisting of wireline and wireless equipment of different technologies.",
                    "In order to keep the network in perfect working order and to guarantee immediate intervention in the event of problems, Globe has to employ numerous types of spare parts that must always be available at short notice."
                ]
            },
            {
                Heading:"The Challenge",
                Paras:[
                    "To simplify operational management, Globe decided to outsource the ownership and management of spare parts, which must be available directly on site when a fault occurs or where Globe requires them.",
                    "In order to guarantee the perfect functioning of the network, the service must be available 24/7/365 and the delivery of spare parts must take place throughout the country within a very short time. The service must also include the collection of the defective part, its repair or eventual disposal. In addition, it is required to continuously update the quantities and types of stock available according to the evolution of  network. In order to optimise the reuse as stockpiles of equipment gradually decommissioned from the network, while reducing operating costs, the service is carried out in synergy with decommissioning activities."
                ]
            },
            {
                Heading:"The Solution",
                Paras:[
                    "To provide the customer with the required service Callcom Global leveraged its territorial organisation and consolidated experience in providing operational services on telecommunications networks. In particular, Callcom Global has dedicated adequate space in its warehouses distributed throughout the country, in which it manages many thousands of spare parts from Globe. This has also been made possible by a specially developed software to catalogue and track the status of each spare part according to network operation processes.",
                    "Callcom Global now guarantees, with its own territorial organisation, the management, delivery and collection of spare parts according to the required SLAs, making thousands of deliveries every year, and, to ensure the availability of the required stocks at all times, it makes use of leading companies specialising in the repair of telecommunications equipment.",
                    "In order to follow the evolution of the network, Callcom Global regularly evaluates with  the necessary additions to the stockpile in synergy with the network rationalisation activities. Such activities also involve the decommissioning of equipment that can be reused as stock.",
                    "In this way, careful planning of decommissioning activities makes it possible to implement circular economy processes to reuse numerous electronic parts, reducing both the quantities to be disposed of and operating costs without any compromise on service quality."
                ]
            }
        ],
        TechPoints:[
            "Network assurance",
            "Managed service",
            "Spare parts management",
            "Circular Economy",
            "Decommissioning"
        ]
    },
    {
        ID:3,
        Heading:"IoT for Infrastructures monitoring",
        Customer:"Energy Operators",
        meta:"IoT monitoring system for energy distribution networks",
        Image:Case5,
        Detail:[
            {
                Heading:"The Scenario",
                Paras:[
                    "Power transmission lines are a key asset for the country. They often cross impervious areas and are subjected to extreme environmental conditions such as high winds and very low temperatures. Increasing the ability to monitor and control these infrastructures allows anticipating the onset of problems and helps to carry out faster and more effective remedial actions."
                ]
            },
            {
                Heading:"The Challenge",
                Paras:[
                    "There is a need to develop an IoT system equipped with sensors, edge computing modules and monitoring data transmission, which is self-powered by renewable energy sources and ensures continuity of operation even in areas with low solar irradiation.",
                    "The environmental context hosting the infrastructures in question does not allow the use of existing equipment and standard operating modes, but the solution must comply with the structural constraints imposed in environments dedicated to the transport of high-voltage energy.",
                    "There are no detailed technological requirements, so there is a collaborative design activity to formalise the specifications of the optimal solution."
                ]
            },
            {
                Heading:"The Solution",
                Paras:[
                    "In order to meet this challenge, we involved the customer in a collaborative design process aimed at identifying innovative and sustainable technological and operational solutions. At the end of the course, we defined the specifications of the necessary technological elements, and then designed and built new electronic components and sensors to optimise the remote control of field systems. We also defined new installation techniques compatible with the impervious context provided by the challenge.",
                ],
                Points:{
                    Heading:"The final outcome of the work led to the design and realisation of an integrated system for the measurement, collection and real-time processing of operating data of high-voltage power lines consisting of:",
                    Point:[
                        "A network of sensors to measure tension loads on power transmission lines and to detect abnormal behaviour in real time, including on pylon stability.",
                        "Collection and initial processing of field data through software modules optimised for an operating environment with maximised processing resources.",
                        "Advanced remote control capabilities of the systems to reduce the need for field interventions.",
                        "Data transmission, entrusted to a LORA network made of best-in-class components. The system handles data transmission via LTE as a backup.",
                        "Weather station to detect a wide range of environmental parameters.",
                        "Energy Station, which ensures power supply even in low irradiation conditions thanks to solar energy and accumulators.",
                        "Specific installation methods and specially manufactured materials.",
                    ]
                },
                Points2:{
                    Heading:"The critical success factors of this project were many:",
                    Point:[
                        "The collaborative way of working with the customer",
                        "The synergy between technology and domain experts",
                        "The strong collaboration between the Digital Solutions team and the client teams"
                    ]
                }
            }
        ],
        TechPoints:[
            "Edge Computing",
            "Lorawan",
            "IoT",
            "Energy Management",
            "Asset Management",
            "Analytics"
        ]
    },
    {
        ID:4,
        Heading:"Application Modernization",
        Customer:"Telco",
        meta:"Development and transformation of Cloud Native applications",
        Image:Case8,
        Detail:[
            {
                Heading:"The Scenario",
                Paras:[
                    "Complex, wide-ranging organisations are moving increasingly large portions of processes and platforms to the cloud in order to accommodate, in a more agile manner, the turbulence and strong acceleration of the market.",
                    "In recent times, there has been a growing realisation that a flexible and light IT infrastructure is a decisive factor for business continuity and success.",
                    "To obtain the maximum benefit from the adoption of the Cloud in its different articulations, public, private and hybrid, it is necessary to transform applications and platforms into Cloud Native logic."
                ]
            },
            {
                Heading:"The Challenge",
                Paras:[
                    "The challenge is to build, in consultation with the customer, an Application Modernisation path cast in the specificity of the strategies adopted for migration to the Cloud, implementing solutions based on Microservices and implemented with DevOps methodologies."
                ]
            },
            {
                Heading:"The Solution",
                Paras:[
                    "The project saw us working on adapting applications and platforms to maximise the benefits of the cloud and enhance the transformation effort of infrastructures and new service delivery methods.",
                    "After an analysis phase to define priorities and critical issues, we started the application modernisation activities.",
                    "Monolithic applications were redesigned by adopting logic based on microservices and standard interfaces for integration with other applications and cloud services: resiliency, agility, operability and observability.",
                    "In this new set-up, each micro-service performs its assigned tasks independently and can always rely on a level of redundancy in the event of blockages or errors.",
                    "In particular, the architecture adopted for the project is based on Kubernetes microservices. In order to reduce the release time of new features, we employed Agile/DevOps methodologies."
                ]
            }
        ],
        TechPoints:[
            "Application modernization",
            "Microservices",
            "Cloud native"
        ]
    },
]