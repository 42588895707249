
import { MainLayout } from '../layouts'
import { Mission ,Figures,Commitments,BusinessSuits,DigitalSolutions,Cutomers, Partners} from '../components'
import AboutImage from "../assets/Banners/About.png"
import ScrollToTop from '../components/scrolltop'

export default function About() {
  return (
    <MainLayout pageName="Callcom Group" Banner={AboutImage}>
      <ScrollToTop/>
      <Mission/>
      <Figures/>
      <Commitments/>
      {/* <BusinessSuits/>
      <DigitalSolutions/>
      <Cutomers/>
      <Partners/> */}
    </MainLayout>
  )
}
