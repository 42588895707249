
import { MainLayout } from '../layouts'
import NewsImage from "../assets/Banners/News.png" 
import Imageashow from "../assets/Contact.jpg"
import GetInTouchWithUs from '../components/news/SectionOne'
import ScrollToTop from '../components/scrolltop'

export default function News() {
  return (
    <MainLayout pageName="Contact Us" Banner={NewsImage}>
      <ScrollToTop/>
      <GetInTouchWithUs />
      <div className='hidden xl:block relative'>
        <div className="w-[100%] h-[303px] absolute z-[9] mb-[580px]" style={{ background: "linear-gradient(rgb(255, 255, 255) 120px, rgba(255, 255, 255, 0) 80%)" }}>
        </div>
        <div className='h-[100vh] relative' style={{ backgroundImage: `url(${Imageashow})`, backgroundSize: 'cover', backgroundPosition: 'center', }}>
          <div className='absolute top-0 h-full bg-gradient-to-b from-white to-black'>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}
