import { useLocation } from "react-router-dom"
import { BlogData } from "../layouts/BlogDetail";
import { MainLayout } from "../layouts";
import { useEffect, useState } from "react";
import Case3 from "../assets/Case/Case3.jpg"
import Case4 from "../assets/Case/Case4.jpg"
import Case5 from "../assets/Case/Case5.jpg"
import Case8 from "../assets/Case/Case8.jpg"
import { FaArrowRight } from "react-icons/fa";


const CaseDetail = () => {
    const location = useLocation();
    const { item } = location.state;

    const [id, setid] = useState(item)


    const BlogDataStructure = [
        {
            ID: 1,
            Image: Case3,
            Head: "Network Transformation: PSTN/ATM",
            meta: "TELCO INFRASTRUCTURES",
            Para: "Network modernisation and structural transformation projects"
        },
        {
            ID: 2,
            Image: Case4,
            Head: "Multi-Vendor Spare Parts Management",
            meta: "TELCO INFRASTRUCTURES",
            Para: "Spare parts management with logistics services and repairs"
        },
        {
            ID: 3,
            Image: Case5,
            Head: "IoT for Infrastructures monitoring",
            meta: "DIGITAL SOLUTIONS",
            Para: "IoT monitoring system for energy distribution networks"
        },
        {
            ID: 4,
            Image: Case8,
            Head: "Application Modernization",
            meta: "DIGITAL SOLUTIONS",
            Para: "Development and transformation of Cloud Native applications"
        }
    ]

    const [Blog, setBlog] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
        BlogData.map((item) => {
            if (item.ID == id) {
                console.log(1)
                setBlog(item)
            }
        })
    }, [id])



    return (
        <>
            <MainLayout>
                <div className="w-[95%] md:w-[90%] lg:w-[85%] m-auto bg-white rounded-md py-6 px-8 shadow-xl relative top-[-100px]">
                    <div className="flex flex-col lg:flex-row justify-start gap-6 mb-10">
                        <div className="basis-[35%] w-[100%] h-[100%]">
                            {console.log(Blog)}
                            <img src={Blog?.Image} alt="" className="w-[100%] min-h-[300px]" />
                        </div>
                        <div className="basis-[60%] flex flex-col gap-8">
                            <h2 className="text-2xl md:text-3xl font-bold">{Blog?.Heading}</h2>
                            <h3 className="text-lg md:text-xl text-gray-500 font-normal">Customer: {Blog?.Customer}</h3>
                            <h4 className="text-xl md:text-2xl border-t-2 border-gray-300 pt-8 text-black font-light">{Blog?.meta}</h4>
                        </div>
                    </div>
                    <div className="flex flex-col lg:flex-row justify-between">
                        <div className="basis-[65%] flex flex-col gap-8">
                            {Blog?.Detail?.map((item, index) => (
                                <div className="flex flex-col gap-4 border-b-[1px] border-gray-300 pb-6" key={index}>
                                    <h2 className="text-black text-2xl font-bold">{item.Heading}</h2>
                                    <div className="flex flex-col gap-6">
                                        {item?.Paras?.map((item2, index2) => (
                                            <p key={index2} className="text-justify text-xl text-gray-500 font-normal">{item2}</p>
                                        ))}
                                    </div>
                                    {item?.Points &&
                                        <div className="flex flex-col gap-4">
                                            <h2 className="text-xl text-gray-500 font-normal">{item?.Points?.Heading}</h2>
                                            <ul className="flex flex-col gap-3 list-disc pl-8">
                                                {item?.Points?.Point.map((item2, index2) => (
                                                    <li
                                                        key={index2}
                                                        className="text-justify text-xl text-gray-500 font-medium">
                                                        {item2}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    }
                                </div>
                            ))}
                        </div>
                        <div className="basis-[30%]">
                            <div className="bg-gray-300 rounded-lg py-4 px-6">
                                <h2 className="mb-3 pb-3 border-b-[1px] border-gray-400 text-xl font-bold">TECH TOUCH POINTS</h2>
                                <ul className="flex flex-col gap-1 text-lg font-medium pl-8 list-disc" >
                                    {Blog?.TechPoints.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="w-[95%] md:w-[90%] lg:w-[85%] m-auto">
                    <h2 className="my-10 text-4xl text-center font-bold">Read also</h2>
                    <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-6 mb-20">
                        {BlogDataStructure.map((item, index) => (
                            <>
                                {item.ID !== id &&
                                    <div
                                        className="relative flex shadow-xl hover:shadow-2xl group justify-between bg-white flex-col md:flex-row ease-in-out duration-300" key={index}
                                        onClick={() => {
                                            setid(item.ID)
                                        }}
                                    >
                                        <div className="basis-[40%] w-[100%] h-[100%] overflow-hidden">
                                            <img src={item.Image} alt="" className='w-[100%] h-[100%] group-hover:scale-125 ease-in-out duration-300' />
                                        </div>
                                        <div className='basis-[60%] py-6 px-4 md:px-8 lg:px-4 xl:px-10 flex flex-col gap-1 md:gap-4 lg:gap-2 xl:gap-4'>
                                            <h2 className='text-xl md:text-2xl lg:text-xl xl:text-2xl font-helvetica font-extrabold'>{item.Head}</h2>
                                            <h3 className='text-lg md:text-2xl lg:text-lg xl:text-xl text-blue-600 font-light'>{item.meta}</h3>
                                            <h4 className='text-lg md:text-2xl lg:text-lg xl:text-xl text-gray-600 font-normal'>{item.Para}</h4>
                                            <div className='flex flex-col w-[100%]  items-end'>
                                                <FaArrowRight className='text-white text-3xl bg-gray-700  rounded-full p-1' />
                                            </div>
                                        </div>
                                    </div>
                                }
                            </>
                        ))}
                    </div>
                </div>

            </MainLayout>
        </>
    )
}

export default CaseDetail