
export default function SectionTwo() {
    return (
        <div className='flex justify-center'>
            <div className='lg:w-10/12 md:w-10/12 w-full py-16 md:pb-20 lg:pb-64 md:px-0 px-4'>
                <h1 className='text-heading font-bold text-center text-lg md:text-xl'>
                SUSTAINABILITY 2021
                </h1>
                <p className='lg:text-4xl font-bold text-center md:text-3xl text-2xl text-black pt-12'>
                    The Sustainability Report 2021</p>
                <div className="font-extralight grid grid-cols-1 lg:grid-cols-2 gap-20 text-black pt-8">
                    <p className="text-xl md:text-2xl font-normal text-justify text-black">
                        Today, the world around us dances on the thread of a web that connects, end to end, every individual on Earth.
                        The circuits through which our lives travel are the fruit of the work that the women and men of Callcom Global have been doing with passion for over a century and whose aim is to connect people and things to the future.
                        <br /><br />
                    The context in which we find ourselves is constantly changing and increasingly digital: high-speed connections are one of the most important drivers of development and the transition to a sustainable energy model has become a global priority.    
                    </p>
                    <p className="text-xl md:text-2xl font-normal text-justify text-black">
                    In this respect, Callcom, now more than ever, is looking to renew itself in a sustainable way. The Group’s objective is to share a mission, already under way, of developing a culture of sustainability, which takes the form of communicating its activities, progress, results and future intentions.
                    <br/><br />
                    The Sustainability Report 2021 is therefore addressed to all Group stakeholders, with the aim of making the actions taken clear and conveying the ESG objectives to stakeholders.</p>
                </div>
            </div>
        </div>
    )
}
