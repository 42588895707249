import { FaFacebook, FaLinkedinIn, FaTwitter } from "react-icons/fa"
import Logo from "../assets/NewLogoWhite.png"

export default function footer() {
  return (
    <>
      <div className='bg-gray-700 w-[100%]'>
        <div className="w-[95%] md:w-[90%] lg:w-[80%] m-auto">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="basis-[15%] w-[15%]">
              <img src={Logo} alt="" className="w-[100%]" />
            </div>
            <div className='flex flex-row md:flex-col lg:flex-row items-center gap-4'>
              <a href="https://www.linkedin.com/callcomglobal" target="_blank">
                <div className="text-4xl p-2 border-2 text-white border-white rounded-full">
                  <FaLinkedinIn className="text-3xl" />
                </div>
              </a>
              <a href="https://www.facebook.com/callcomglobal" target="_blank">
                <div className="text-4xl p-2 border-2 text-white border-white rounded-full">
                  <FaFacebook className="text-3xl" />
                </div>
              </a>
              <a href="https://www.twitter.com/callcomglobal" target="_blank">
                <div className="text-4xl p-2 border-2 text-white border-white rounded-full">
                  <FaTwitter className="text-3xl" />
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="w-[100%] bg-gray-600">
        <div className="w-[95%] md:w-[90%] lg:w-[80%] m-auto">

          <p className='text-left py-2 font-normal text-gray-300 text-lg'>
            © 2023 Callcom Global™. All rights reserved.
          </p>
        </div>
      </div>
    </>
  )
}
