
export default function sectionOne() {
    return (
        <div className='flex justify-center'>
            <div className='lg:w-8/12 md:w-full w-full py-16 md:px-0 px-4 text-center'>
                <h1 className='text-heading font-bold'>
                    Our Commitment
                </h1>
                <p className='lg:text-[38px] md:text-[28px] text-7xl font-bold text-black pt-12'>
                    We build the future through our team’s ability to perform and innovate
                </p>
                <p className="text-black text-lg md:text-xl font-normal pb-5 pt-12">
                    The world is constantly changing, and increasingly digital, high-speed connections are one of the most important drivers of development, with the transition to a sustainable energy model becoming a global priority.
                </p>
                <p className="text-black text-lg font-normal md:text-xl">
                    In this context, Callcom Global combines a century of experience in the design, construction and maintenance of large-scale strategic infrastructures with solid skills in delivering services and projects of high technological and digital complexity.
                </p>
            </div>
        </div>
    )
}
