import About from "../pages/about";
import Business from "../pages/business";
import Innovation from "../pages/innovation"
import Sustainability from "../pages/sustainabilty"
import CaseStudies from "../pages/case-studies";
import LifeInSirti from "../pages/lifeInSirti";
import News from "../pages/news"
import Landing from "../pages/landing";
import ProffesionalLife from "../pages/Proffesional";
import CaseDetail from "../pages/CaseDetail";
const routes = [
    {
        path: '/',
        element: <Landing />
    },
    {
        path: '/Services',
        element: <Business />
    },
    {
        path: "/about",
        element: <About />,
    },
    {
        path: '/innovation',
        element: <Innovation />
    },
    {
        path: '/sustainability',
        element: <Sustainability />
    },
    // {
    //     path: '/case-studies',
    //     element: <CaseStudies />
    // },
    {
        path: '/life-in-Callcom Global',
        element: <LifeInSirti />
    },
    {
        path: '/Careers-opportunities',
        element: <ProffesionalLife />
    },
    {
        path: '/Contact',
        element: <News />
    },
    {
        path: '/case-study/detail',
        element: <CaseDetail />
    }

]
export default routes;