
import BackgroundImage from "../../assets/svg/theGroup/businessSuit.jpg"
import stats from "../../assets/svg/theGroup/stats.svg"
import avatar from "../../assets/svg/theGroup/avatar.svg"
export default function BusinessSuits() {
    return (
        <div>
            <h1 className=' font-semibold bg-heading text-white py-6 text-center'>
                OUR BUSINESS UNITS
            </h1>
            <div className='py-16 flex justify-center' style={{ backgroundImage: `url(${BackgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center', }}>

                <div className='lg:w-8/12 md:w-10/12 w-full py-16 md:px-0 px-4 flex'>
                    <div className='lg:w-1/2 w-full'>
                        <p className='text-[45px] text-white pb-6'>
                            Telco Solutions

                        </p>
                        <p className='text-white font-extralight text-lg'>
                        We are one of the leading providers of telecommunication services across the US.
                            Thanks to our experience and in-depth knowledge of the industry since 1993, our regionally distributed operational staff and extensive partner network, we offer Service Providers and Infrastructure Players with design, management, implementation and maintenance services for networks and sites for…
                        </p>
                        <div className='grid md:grid-cols-2 grid-cols-1 gap-5 mt-10'>
                            <div className='bg-heading p-5 space-y-5'>
                                <p className='text-center text-2xl font-bold text-secondary'>
                                    HIGHLIGHTS
                                </p>
                                <div className='flex space-x-5 text-white'>
                                    <img src={avatar} className='w-12' />
                                    <div>
                                        <p className='text-[25px]'>
                                        100+
                                        </p>
                                        <p className='text-sm'>
                                            Customers
                                        </p>
                                    </div>
                                </div>
                                <div className='flex space-x-5 text-white'>
                                    <img src={stats} className='w-12' />
                                    <div>
                                        <p className='text-[25px]'>

                                        $45M
                                        </p>
                                        <p className='text-sm'>
                                            Turnover in 2022
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='bg-heading p-5 space-y-5'>
                                <p className='text-left text-2xl font-bold text-secondary'>
                                    PORTFOLIO
                                </p>
                                <p className='text-white text-lg pr-12 pb-12'>
                                    Voice Termination
                                    Managed Termination
                                    Messaging Termination<br></br>
                                    Data<br></br>
                                    Anti Fraud
                                    Radio Networks
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
