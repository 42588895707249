import Logo from "../../assets/NewLogoWhite.png"
import avater from "../../assets/svg/theGroup/avatar.svg"
import stats from "../../assets/svg/theGroup/stats.svg"
export default function SectionOne() {
  return (
    <div className='flex justify-center'>
      <div className='lg:w-8/12 md:w-10/12 w-full py-16 md:px-0 px-4'>
        <div className="grid md:grid-cols-3 grid-cols-1 md:gap-10 md:space-y-0 gap-0 space-y-6">
          <div className="col-span-2">
            <h1 className='text-heading font-bold'>
              Our Conduct
            </h1>
            <p className='lg:text-[45px] md:text-[38px] text-[20px] text-black font-semibold pt-12'>
              Expertise and operational capacity always at the service of our customers
            </p>
            <div className='w-24 bg-heading pb-0.5 mt-6'>
            </div>
            <p className='text-black font-extralight text-lg pt-6'>
              We are US leading provider of telecommunications services.
              Thanks to our experience and in-depth knowledge of the industry since 1993, our regionally distributed operational staff and extensive partner network, we offer Service Providers and Infrastructure Players design, management, implementation and maintenance services for telecommunications networks and sites.
            </p>
            <p className='text-black font-extralight text-lg py-6'>
              On the strength of our operational and engineering expertise, we now confidently accompany our customers as advisors in their project choices and during the realisation of complex transformation, modernisation and optimisation projects of network infrastructures and systems.
            </p>
            <p className='text-black font-extralight text-lg'>
              We are the reference company for the implementation of ultra-wideband network development plans. Every day we work to ensure the continuity of service of TLC infrastructures, from individual subscriber lines to backbones, data centres and the most strategic power plants, performing an essential service for the digital transformation and the social and industrial development of the country.
            </p>
          </div>
          <div>
            <div className="bg-heading p-2 py-10 flex justify-center" style={{ clipPath: 'polygon(calc(100% - 28px) 0, 100% 28px, 100% 100%, 0 100%, 0 0)' }}>
              <img src={Logo} alt="logo" className="w-56" />
            </div>
            <div className="bg-gray-200 p-5">
              <h1 className='text-heading font-bold pb-4 border-b border-gray-300 pt-4'>
                OUR MISSION
              </h1>
              <p className=' text-[16px] text-black font-semibold pt-4'>
                “We build modern and efficient telecommunications infrastructures and ensure their proper functioning over time. The result of our work improves people’s lives and contributes to the social and industrial development of the country.”
              </p>
              <h1 className='text-heading font-bold pb-4 border-b border-gray-300 pt-12'>
                HIGHLIGHTS
              </h1>
              <div className="grid grid-cols-2 py-10">
                <div className="flex flex-col items-center ">
                  <div className="radial-progress text-heading " style={{ "--value": 100, "--size": "5rem", "--thickness": "2px", "color": 'white' }} role="progressbar">
                    <div className="radial-progress text-heading " style={{ "--value": 25, "--size": "5rem", "--thickness": "3px", }} role="progressbar">
                      <img src={avater} className="w-10 " />
                    </div>

                  </div>
                  <p className="font-bold text-black text-center pt-6">
                    100+
                  </p>
                  <p className=" text-black text-sm text-center">
                    Customers
                  </p>
                </div>
                <div className="flex flex-col items-center ">
                  <div className="radial-progress text-heading " style={{ "--value": 100, "--size": "5rem", "--thickness": "2px", "color": 'white' }} role="progressbar">
                    <div className="radial-progress text-heading " style={{ "--value": 25, "--size": "5rem", "--thickness": "3px", }} role="progressbar">
                      <img src={stats} className="w-10 " />
                    </div>

                  </div>
                  <p className="font-bold text-black text-center pt-6">
                  $45M
                  </p>
                  <p className=" text-black text-sm text-center">
                    Turnover (2022)
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
