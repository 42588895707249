
export default function SectionThree() {
    return (
        <div className='flex justify-center bg-black'>
            <div className='lg:w-8/12 md:w-10/12 w-full py-16 md:px-0 px-4 flex'>
                <div className="lg:w-1/2 w-full">
                    <h1 className='text-heading font-bold'>
                        OUR COMMITMENT
                    </h1>
                    <p className='lg:text-[35px] md:text-[38px] text-[20px] text-white font-semibold pt-12'>
                        We are a recognised player in the world of telecommunications </p>
                    <p className="font-extralight text-white pt-6">
                    In our long history, we have contributed to the development of the majority of our country’s telecommunications infrastructure, demonstrating reliability, competence and care for quality. Customers choose us because of our culture of technical competence and operational capacity to tackle new and complex problems with determination. We love to work as a team in full compliance with the rules, and we care about safety and the environment in which we operate, because the value we create must be sustainable and long-lasting as a testament to our passion for a job well done.
                    </p>
                </div>
            </div>
        </div>
    )
}
