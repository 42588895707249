
import image1 from "../../assets/Customers/1.jpg"
import image2 from "../../assets/Customers/2.jpg"
import image3 from "../../assets/Customers/3.jpg"
import image4 from "../../assets/Customers/4.png"
import image5 from "../../assets/Customers/5.jpg"
import image6 from "../../assets/Customers/6.jpg"
import image7 from "../../assets/Customers/7.png"
import image8 from "../../assets/Customers/8.jpg"
import image9 from "../../assets/Customers/9.jpg"
import image10 from "../../assets/Customers/10.jpg"
import image11 from "../../assets/Customers/11.jpg"
import image12 from "../../assets/Customers/12.jpg"


export default function Cutomers() {

    const ImagesData=[
        image1,
        image2,
        image3,
        image4,
        image5,
        image6,
        image7,
        image8,
        image9,
        image10,
        image11,
        image12,
    ]

    return (
        <div className='flex justify-center'>
            <div className='lg:w-8/12 md:w-10/12 w-full py-16 md:px-0 px-4'>
                <h1 className='text-heading font-bold text-center'>
                    CUSTOMERS

                </h1>
                <p className='lg:text-[38px] md:text-[30px] text-[24px] text-black font-semibold pt-12 leading-9 text-center'>
                    Companies that change the future
                </p>
                <div className='grid lg:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-5 py-10'>
                    {ImagesData.map((item,index)=>(
                    <div className='py-1 border border-gray-200 flex justify-center' key={index}>
                        <img src={item} className='w-44' />
                    </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
