
const Section2 = () => {
  return (
    <div className='flex flex-col justify-center py-16 md:pb-20 lg:pb-30'>
            <div className='lg:w-8/12 md:w-10/12 w-full  md:px-0 px-4 m-auto'>
                <h1 className='text-heading text-xl md:text-2xl font-boldn text-center'>
                WELCOME ON BOARD
                </h1>
                <p className='lg:text-6xl font-bold text-center md:text-4xl text-3xl text-black pt-12'>
                People talk about their first experiences at Callcom global
                </p>
                <p className="text-xl md:text-2xl py-10 text-center font-light text-black">
                We welcome some colleagues who have recently joined the Callcom global Group. Find out about their first experiences in the company, how their days unfold and the activities they engage in.
                </p>
            </div>
            </div>
  )
}

export default Section2