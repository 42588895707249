
export default function SectionOne() {
    return (
        <div className='flex justify-center'>
            <div className='lg:w-8/12 md:w-10/12 w-full my-16 md:mb-20 lg:mb-36 md:px-0 px-4'>
                <h1 className='text-heading font-bold text-center text-lg md:text-xl'>
                    Callcom Global, A Life-Style
                </h1>
                <p className='lg:text-4xl font-bold text-center md:text-3xl text-2xl text-black pt-12'>
                    We build the national technological infrastructure for an innovative country</p>
                <div className="text-xl md:text-2xl text-justify py-10 font-light text-black">
                    <p>
                        At Callcom Global we give a special meaning to our work: we are committed to building a better working environment, for our people, our customers and for the development of the country system. We adopt a flexible and listening-oriented working approach with a collaborative and inclusive culture. Our value system is based on behaviour that puts the person at the centre of our work.
                    </p>
                </div>
            </div>
        </div>
    )
}
