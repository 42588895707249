import { HiIdentification } from "react-icons/hi2"
import Image1 from "../../assets/Points/lavorresirti.jpg"
import Image2 from "../../assets/Points/2.jpg"
import Image3 from "../../assets/Points/3.jpg"
import Image4 from "../../assets/Points/4.jpg"
import { useState } from "react"
import {IoIosPaper} from "react-icons/io"
import {IoPeople} from "react-icons/io5"
import { MdOutlineQuestionAnswer } from "react-icons/md";

const Section1 = () => {

    const [Open, setOpen] = useState(null)

    const Data = [
        {
            Title: "Position identification",
            Icon: HiIdentification,
            Image: Image1,
            Para: "Once your application has been placed on the site, either as a response to a specific position or as an unsolicited application, your CV will be carefully evaluated in order to identify a real match to profiles/positions open within our company. If you meet the requirements, you will be contacted for more information on the next steps in the selection process and a possible initial interview.",
            Tip: "Read the job description in detail to understand whether the open position matches your interest, paying attention to the specific requirements, the peculiarities of the role and the location/area of employment. If your profile matches, apply. Otherwise, send us your CV through an unsolicited application—it will remain in our database available for future opportunities."
        },
        {
            Title: "Writing a CV",
            Icon: IoIosPaper,
            Image: Image2,
            Tip: "Make sure that your CV contains all personal data (full name, address, telephone number, date of birth, citizenship, work permit for foreign nationals, driving licence and e-mail) and that it is up to date since your last job. Clarity and conciseness are essential to highlight at a glance your most significant experiences and the companies you have worked for (the most relevant to the position sought above)."
        },
        {
            Title: "Human Resources interview",
            Icon: IoPeople,
            Image: Image3,
            Para: "You will be interviewed by the Human Resources team in order to discuss the reasons for your application, your academic and professional background and your expectations.",
            Tip: "Adopt a communication style that represents you and is characterised by respect. Use a positive and professional approach. Reinforce your answers by bringing concrete examples and recounting projects in which you have collaborated. Be clear about your demands and expectations. In case of an online interview, make sure you have a good connection at your disposal and that you connect from a suitable place."
        },
        {
            Title: "Interview with the future manager",
            Icon: MdOutlineQuestionAnswer,
            Image: Image4,
            Para: "If you have passed the previous steps of the selection process, you will access the final stage which consists of an interview with Human Resources and the Head of Department, who will determine your possible entry into the company. If successful, you will have the opportunity to join the smart working agreement if compatible with your role and activity, and work remotely in accordance with our company policies.",
            Tip: "Prepare all the technical, logistical and organisational questions you need to ask in order to best evaluate the proposed position. Detail your previous work experience, focusing on your responsibilities and working tools and methods."
        }
    ]

    return (
        <div className='flex flex-col justify-center py-16 md:pb-20 lg:pb-30'>
            <div className='lg:w-8/12 md:w-10/12 w-full  md:px-0 px-4 m-auto'>
                <h1 className='text-heading text-xl md:text-2xl font-boldn text-center'>
                    The Candidate Journey at Callcom
                </h1>
                <p className='lg:text-6xl font-bold text-center md:text-4xl text-3xl text-black pt-12'>
                    Suggestions from our recruiters
                </p>
                <p className="text-xl md:text-2xl py-10 text-center font-light text-black">
                    Do you want to apply for a specific position? Do you want to send an unsolicited application? Whatever your aspiration, discover the Callcom global Recruiting team’s tips for greater success.
                </p>
            </div>
            <div className="w[95%] md:w-[90%] lg:w-[80%] m-auto flex flex-col gap-4">
                {Data.map((item, index) => (
                    <div key={index} className="">
                        <div className="flex flex-row py-6 px-10 justify-start gap-8 text-black font-medium cursor-pointer items-center text-3xl bg-[#f4f4f4]"
                        onClick={() => {
                            if(Open==index){
                                setOpen(null)
                            } else{
                                setOpen(index)
                            }
                        }}>
                            <div className="bg-black p-3 text-white rounded-full">
                            <item.Icon/>
                            </div>
                            <h2>{item.Title}</h2>
                        </div>
                        {Open == index &&
                            <div className="flex flex-col w-[90%] m-auto bg-[#f4f4f4]">
                                <img src={item.Image} alt="" />
                                <div className="flex flex-col gap-6 text-black py-4 px-6">
                                    <p className="text-lg md:text-xl font-normal ">{item?.Para}</p>
                                    <h2 className="text-xl font-bold md:text-2xl border-b-1 border-gray-400 py-8">Tips from recruiters</h2>
                                    <p className="text-lg md:text-xl font-normal ">{item.Tip}</p>
                                </div>
                            </div>}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Section1
