
import avater from "../../assets/svg/theGroup/avatar.svg"
export default function SectionTwo() {
    return (
        <div className='flex justify-center bg-heading'>
            <div className='lg:w-8/12 md:w-10/12 w-full py-16 md:px-0 px-4'>
                <h1 className='text-white font-bold text-center'>
                    FACT AND FIGURES
                </h1>
                <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 py-16 gap-16">
                    <div className="flex flex-col items-center ">
                        <div className="radial-progress text-[#170C66] " style={{ "--value": 100, "--size": "5rem", "--thickness": "2px", "color": 'white' }} role="progressbar">
                            <div className="radial-progress text-[#170C66] " style={{ "--value": 25, "--size": "5rem", "--thickness": "3px", }} role="progressbar">
                                <img src={avater} className="w-10 " />
                            </div>

                        </div>
                        <p className="font-bold text-white text-center pt-6 text-[35px]">
                            100 + 
                        </p>
                        <p className=" text-white text-sm text-center font-extralight">
                            Customers
                        </p>
                    </div>
                    <div className="flex flex-col items-center ">
                        <div className="radial-progress text-[#3D7BEC] " style={{ "--value": 100, "--size": "5rem", "--thickness": "2px", "color": 'white' }} role="progressbar">
                            <div className="radial-progress text-[#3D7BEC]" style={{ "--value": 25, "--size": "5rem", "--thickness": "3px", }} role="progressbar">
                                <img src={avater} className="w-10 " />
                            </div>

                        </div>
                        <p className="font-bold text-white text-center pt-6 text-[35px]">
                            1 Billion
                        </p>
                        <p className=" text-white text-sm text-center font-extralight">
                            International Minutes in 2023
                        </p>
                    </div>
                    <div className="flex flex-col items-center ">
                        <div className="radial-progress text-[#173D7A] " style={{ "--value": 100, "--size": "5rem", "--thickness": "2px", "color": 'white' }} role="progressbar">
                            <div className="radial-progress text-[#173D7A] " style={{ "--value": 25, "--size": "5rem", "--thickness": "3px", }} role="progressbar">
                                <img src={avater} className="w-10 " />
                            </div>

                        </div>
                        <p className="font-bold text-white text-center pt-6 text-[35px]">
                            48 + M
                        </p>
                        <p className=" text-white text-sm text-center font-extralight">
                            mobile radio users served by the infrastructure maintained by Callcom Global
                        </p>
                    </div>
                    <div className="flex flex-col items-center ">
                        <div className="radial-progress text-[#44B2F7] " style={{ "--value": 100, "--size": "5rem", "--thickness": "2px", "color": 'white' }} role="progressbar">
                            <div className="radial-progress text-[#44B2F7] " style={{ "--value": 25, "--size": "5rem", "--thickness": "3px", }} role="progressbar">
                                <img src={avater} className="w-10 " />
                            </div>

                        </div>
                        <p className="font-bold text-white text-center pt-6 text-[35px]">
                            420.000 +
                        </p>
                        <p className=" text-white text-sm text-center font-extralight">
                            maintenance operations per year on telecommunications sites and equipment


                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
