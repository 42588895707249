
import Integerity from "../../assets/svg/theGroup/integerity.svg"
import center from "../../assets/svg/theGroup/canter.svg"
import Environmental from "../../assets/svg/theGroup/environmental.svg"
import Movement from "../../assets/svg/theGroup/movement.svg"
export default function SectionTwo() {
    return (
        <div className='flex justify-center'>
            <div className='lg:w-8/12 md:w-10/12 w-full py-16 md:px-0 px-4'>
                <h1 className='text-heading font-bold'>
                    INNOVATION DRIVERS
                </h1>
                <p className='lg:text-[38px] md:text-[28px] text-[24px] text-black font-semibold pt-12 leading-9'>
                    We innovate to make the world a better place   </p>

                <div className='pt-12 grid md:grid-cols-2 grid-cols-1 gap-14'>
                    <div className='flex space-x-6'>
                        <img className='w-32' src={Integerity} />
                        <div className='space-y-5'>
                            <p className='text-[20px] font-extrabold text-black'>
                                CAPACITY TO INNOVATE
                            </p>
                            <p className='text-black font-extralight'>
                                High degree of innovation that <br />
                                constitutes a distinctive value
                            </p>
                        </div>
                    </div>
                    <div className='flex space-x-6'>
                        <img className='w-32' src={Environmental} />
                        <div className='space-y-5'>
                            <p className='text-[20px] font-extrabold text-black'>
                                SUSTAINABILITY
                            </p>
                            <p className='text-black font-extralight'>
                                Economically sustainable and with  <br />
                                a great cost-beneﬁt ratio
                            </p>
                        </div>
                    </div>
                    <div className='flex space-x-6'>
                        <img className='w-32' src={center} />
                        <div className='space-y-5'>
                            <p className='text-[20px] font-extrabold text-black'>
                                RELIABILITY
                            </p>
                            <p className='text-black font-extralight'>
                                Ready-to-use solutions that have passed<br />
                                the prototype and experimentation<br />
                                phases
                            </p>
                        </div>
                    </div>
                    <div className='flex space-x-6'>
                        <img className='w-32' src={Movement} />
                        <div className='space-y-5'>
                            <p className='text-[20px] font-extrabold text-black'>
                            SCALABILITY
                            </p>
                            <p className='text-black font-extralight'>

                            Level of industrialisation that allows large <br />
                            project volumes to be covered
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
