import Life1 from "../../assets/LifeBlog/Image1.jpg"
import Life2 from "../../assets/LifeBlog/Image2.jpg"
import Life3 from "../../assets/LifeBlog/Image3.jpg"

export default function sectionTwo() {
    return (
        <div className='flex justify-center'>
            <div className='lg:w-8/12 md:w-10/12 w-full py-16 md:pb-20 lg:pb-36 md:px-0 px-4'>
                <h1 className='text-heading font-bold text-center text-lg md:text-xl'>
                    ACTIVE LISTENING, INITIATIVES IN SUPPORT OF PEOPLE
                </h1>
                <p className='lg:text-5xl font-bold text-center md:text-4xl text-2xl pt-10 pb-20 text-black'>
                    We build the national technological infrastructure for an innovative country</p>

                <div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-5'>
                    <div >
                        <div className="bg-heading p-5 py-24 flex justify-center" style={{ clipPath: 'polygon(calc(100% - 28px) 0, 100% 28px, 100% 100%, 0 100%, 0 0)', backgroundImage: `url(${Life1})`, backgroundSize: 'cover', backgroundPosition: 'center', }}>

                        </div>
                        <div className="p-5" style={{ background: "linear-gradient(270deg, #05165D, #0031B6)" }}>
                            <p className="text-white font-bold text-2xl md:text-3xl  pt-4">
                                CLIMATE SURVEY
                            </p>
                            <p className='text-lg md:text-xl text-white font-extralight pt-4  text-left pb-20'>
                                Activating an active listening tool within the company that allows you to build the future through concrete…
                            </p>
                        </div>
                    </div>
                    <div >
                        <div className="bg-heading p-5 py-14 md:py-24 flex justify-center" style={{ clipPath: 'polygon(calc(100% - 28px) 0, 100% 28px, 100% 100%, 0 100%, 0 0)', backgroundImage: `url(${Life2})`, backgroundSize: 'cover', backgroundPosition: 'center', }}>

                        </div>
                        <div className="p-5" style={{ background: "linear-gradient(270deg, #05165D, #0031B6)" }}>
                            <p className="text-white font-bold text-2xl md:text-3xl  pt-4">
                                SMART WORKING
                            </p>
                            <p className='text-lg md:text-xl text-white font-extralight pt-4  text-left pb-20'>
                                Reconciling private life and work life by building a solid foundation between employee and manager…
                            </p>
                        </div>
                    </div>
                    <div >
                        <div className="bg-heading p-5 py-14 md:py-24 flex justify-center" style={{ clipPath: 'polygon(calc(100% - 28px) 0, 100% 28px, 100% 100%, 0 100%, 0 0)', backgroundImage: `url(${Life3})`, backgroundSize: 'cover', backgroundPosition: 'center', }}>

                        </div>
                        <div className="p-5" style={{ background: "linear-gradient(270deg, #05165D, #0031B6)" }}>
                            <p className="text-white font-bold text-2xl md:text-3xl  pt-4">
                                #STEMiNPURPLE
                            </p>
                            <p className='text-lg md:text-xl text-white font-extralight pt-4  text-left pb-20'>
                                Callcom and Nokia’s project to promote female empowerment and inclusiveness in the…
                            </p>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
