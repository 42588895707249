
import { MainLayout } from '../layouts'
import { SectionOne, SectionTwo, SectionThree,Cutomers, BusinessSuits, DigitalSolutions, Partners } from '../components'
import BackgroundImage from "../assets/svg/home/bgConduct.jpg"
import BusinessImage from "../assets/Banners/Business.png"
import ScrollToTop from '../components/scrolltop'

export default function Business() {
  return (
    <MainLayout pageName="Services" Banner={BusinessImage}>
      <ScrollToTop/>
      <SectionOne />
      <BusinessSuits/>
      <DigitalSolutions/>
      <div className='py-64' style={{ backgroundImage: `url(${BackgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center', }}>

      </div>
      <SectionTwo />
      {/* <SectionThree /> */}
      <Cutomers/>
      <Partners/>

    </MainLayout>
  )
}
