
import Network from "../../assets/svg/network.jpg"
import digital from "../../assets/svg/digital.jpg"
export default function sectionTwo() {
    return (
        <div className='flex justify-center'>
            <div className='lg:w-7/12 md:w-10/12 w-full py-16 md:px-0 px-4 text-center'>
                <h1 className='text-heading font-bold'>
                BUSINESS
                </h1>
                <p className='lg:text-[38px] md:text-[28px] text-[24px] text-black font-semibold pt-12'>
                    A multidisciplinary offer at the service of our customers
                </p>

                <div className="grid grid-cols-2 gap-6 pt-12">
                    <div className="h-full">
                        <div className="bg-heading p-5 py-14 flex justify-center" style={{ clipPath: 'polygon(calc(100% - 28px) 0, 100% 28px, 100% 100%, 0 100%, 0 0)', backgroundImage: `url(${Network})`, backgroundSize: 'cover', backgroundPosition: 'center', }}>
                            <p className='lg:text-[34px] md:text-[28px] text-[24px] text-white font-semibold pt-12'>
                                Telco Solutions
                            </p>
                        </div>
                        <div className="bg-gray-200 p-5">
                            <p className='text-[16px] md:text-[20px] text-black font-extralight pt-4  text-left'>
                                Since 1993, we have been leading providers of telecommunication services across the US thanks to our in-depth knowledge of networks, our operational capacity and our widespread presence on the ground…  </p>
                        </div>
                    </div>
                    <div className="h-full">
                        <div className="bg-heading p-5 py-14 flex justify-center" style={{ clipPath: 'polygon(calc(100% - 28px) 0, 100% 28px, 100% 100%, 0 100%, 0 0)', backgroundImage: `url(${digital})`, backgroundSize: 'cover', backgroundPosition: 'center', }}>
                            <p className='lg:text-[34px] md:text-[28px] text-[24px] text-white font-semibold pt-12'>
                                Digital Solutions
                            </p>
                        </div>
                        <div className="bg-gray-200 p-5">

                            <p className='text-[16px] md:text-[20px] text-black font-extralight pt-4  text-left'>

                                Thanks to our digital expertise confirmed by specialist certifications and our broad portfolio of solutions, we are one of the key system integrators in the digital transformation sector in US…
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}
