import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Logo from "../assets/NewLogoWhite.png"
import Instagram from "../assets/svg/instagram.svg"
import Vimeo from "../assets/svg/vimeo.svg"
import Linkdin from "../assets/svg/linkdin.svg"
import Burger from "../assets/svg/burger.svg"
import Close from "../assets/svg/close.svg"
import { Link } from 'react-router-dom'
// import video from "../assets/svg/video.mp4"
import BanenrImage from "../assets/BGBanner.jpg"

export default function Header({ pageName }) {
    const [isSticky, setIsSticky] = useState(false);

    const handleScroll = () => {
        if (window.scrollY > 250) {
            setIsSticky(true);
        } else {
            setIsSticky(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        // Cleanup function to remove the event listener when component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []); // Empty dependency array means this effect runs only once after the initial render
    return (
        <div className='relative h-[90vh]'>
            <div className="absolute w-full top-0 h-full ">
                {/* <video autoPlay loop muted className='h-full w-full object-cover'>
                    <source src={video} type="video/mp4" />
                    Your browser does not support the video tag.
                </video> */}
                <img src={BanenrImage} alt="" className='w-[100%] h-[100%]' />
            </div>
            <div className='absolute top-0 w-full bg-black h-full bg-opacity-20 '>
                <div className={`z-[999999]  w-full top-0 flex justify-between items-center  lg:px-14 px-4  ${isSticky ? 'bg-black text-white fixed' : 'bg-transparent text-white'}`}>
                    <div className={`flex items-center py-3 space-x-10 `}>
                        <Link to={'/'}>
                            <img src={Logo} alt='logo' className='2xl:w-48 lg:w-32 w-32 cursor-pointer 2xl:-ml-8 lg:-ml-4 2xl:py-0 py-4 -ml-2 ' />
                        </Link>

                        <div className='lg:flex hidden space-x-6 h-6 items-end'>
                            <Link to={'/about'}>
                                <p className='font-helvetica text-[16px] font-semibold text-normal  transition duration-300 hover:border-t-2 border-gray-400 text-white cursor-pointer pt-2'>
                                    About Callcom
                                </p>
                            </Link>
                            <Link to={'/Services'}>
                                <p className='font-helvetica text-[16px] font-semibold text-normal  transition duration-300 hover:border-t-2 border-gray-400 text-white cursor-pointer pt-2'>
                                    Services
                                </p>
                            </Link>
                            <Link to={'/innovation'}>
                                <p className='font-helvetica text-[16px] font-semibold text-normal  transition duration-300 hover:border-t-2 border-gray-400 text-white cursor-pointer pt-2'>
                                    Innovation
                                </p>
                            </Link>
                            <Link to='/sustainability'>
                                <p className='font-helvetica text-[16px] font-semibold text-normal  transition duration-300 hover:border-t-2 border-gray-400 text-white cursor-pointer pt-2'>
                                    Sustainability
                                </p>
                            </Link>
                            {/* <Link to='/case-studies'>
                                <p className='font-helvetica text-[16px] font-semibold text-normal  transition duration-300 hover:border-t-2 border-gray-400 text-white cursor-pointer pt-2'>
                                    Case Studies
                                </p>
                            </Link> */}
                            <div className='group relative'>
                                <p className='font-helvetica text-[16px] font-semibold text-normal  transition duration-300 hover:border-t-2 border-gray-400 text-white cursor-pointer pt-2 ease-in-out '>
                                    Working at Callcom
                                    <div className='relative w-max group-hover:flex hidden bg-black/50'>
                                        <div className='p-3 absolute  flex-col gap-2 bg-black/50 w-max'>
                                            <Link to={'/life-in-Callcom Global'}>

                                                <p className='font-helvetica relative text-[16px] font-semibold text-normal  transition duration-300 text-gray-200 hover:text-white cursor-pointer pt-2 ease-in-out '>
                                                    Life at Callcom
                                                </p>
                                            </Link>
                                            <Link to={'/Careers-opportunities'}>
                                                <p className='font-helvetica relative text-[16px] font-semibold text-normal  transition duration-300 text-gray-200 hover:text-white cursor-pointer pt-2 ease-in-out '>
                                                Careers
                                                </p>
                                            </Link>
                                        </div>
                                    </div>
                                </p>
                            </div>
                            <Link to={'/Contact'}>
                                <p className='font-helvetica text-[16px] font-semibold text-normal  transition duration-300 hover:border-t-2 border-gray-400 text-white cursor-pointer'>
                                    Contact
                                </p>
                            </Link>
                        </div>
                    </div>
                    <div className="drawer drawer-end lg:hidden dropdown dropdown-end w-10 flex justify-end">
                        <input id="my-drawer-4" type="checkbox" className="drawer-toggle" />
                        <div className="drawer-content">
                            {/* Page content here */}
                            <label htmlFor="my-drawer-4" className="drawer-button bg-black border-none focus:bg-gray-100 m-1 w-12"><img src={Burger} alt='burger' className='cursor-pointer' /></label>
                        </div>
                        <div className="drawer-side z-50">
                            <ul className="menu p-4 w-full bg-black min-h-full text-base-content">

                                <div className=' flex justify-end'>
                                    <div className='w-[300px]'>
                                        <div className='flex justify-between items-center pb-12'>
                                            <Link to={'/Services'}>
                                                <img src={Logo} alt='logo' className='2xl:w-logoW lg:w-56 w-32 cursor-pointer' />
                                            </Link>
                                            <label htmlFor="my-drawer-4" aria-label="close sidebar" className="drawer-overlay flex justify-end"><img src={Close} alt='burger' className='w-5 cursor-pointer' /></label>
                                        </div>
                                        {/* Sidebar content here */}
                                        <div>

                                            <Link to={'/about'}>
                                                <p className='font-helvetica text-sm py-2 border-b border-gray-400 font-normal text-white cursor-pointer'>
                                                    About Callcom
                                                </p>
                                            </Link>
                                            <Link to={'/'}>
                                                <p className='font-helvetica text-sm py-2 border-b border-gray-400 font-normal text-white cursor-pointer'>
                                                    Services
                                                </p>
                                            </Link>
                                            <Link to={'/innovation'}>
                                                <p className='font-helvetica text-sm py-2 border-b border-gray-400 font-normal text-white cursor-pointer'>
                                                    Innovation
                                                </p>
                                            </Link>
                                            <Link to='/sustainability'>
                                                <p className='font-helvetica text-sm py-2 border-b border-gray-400 font-normal text-white cursor-pointer'>
                                                    Sustainability
                                                </p>
                                            </Link>
                                            {/* <Link to='/case-studies'>
                                                <p className='font-helvetica text-sm py-2 border-b border-gray-400 font-normal text-white cursor-pointer'>
                                                    Case Studies
                                                </p>
                                            </Link> */}
                                            <div className='group relative'>
                                                <p className='font-helvetica text-normal  transition duration-300 hover:border-t-2 border-gray-400 text-white cursor-pointer pt-2 ease-in-out '>
                                                    Working at Callcom
                                                    <div className='relative w-max group-hover:flex hidden bg-black/50'>
                                                        <div className='p-3  flex-col gap-2 bg-black/50 w-max'>
                                                            <Link to={'/life-in-Callcom Global'}>

                                                                <p className='font-helvetica relative  text-normal  transition duration-300 text-gray-200 hover:text-white cursor-pointer pt-2 ease-in-out '>
                                                                    Life at Callcom
                                                                </p>
                                                            </Link>
                                                            <Link to={'/Careers-opportunities'}>
                                                                <p className='font-helvetica relative text-normal  transition duration-300 text-gray-200 hover:text-white cursor-pointer pt-2 ease-in-out '>
                                                                Careers
                                                                </p>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </p>
                                            </div>
                                            <Link to={'/Contact'}>
                                                <p className='font-helvetica text-sm py-2 border-b border-gray-400 font-normal text-white cursor-pointer'>
                                                    Contact
                                                </p>
                                            </Link>
                                            <div className='flex space-x-4 pt-6'>
                                                <a href='https://www.instagram.com/iamkaptin/'>
                                                    <img src={Instagram} alt='insta' className='transition-transform w-6 transform-gpu hover:scale-105 focus:scale-105' />
                                                </a>
                                                <a href="https://vimeo.com/831364538">
                                                    <img src={Vimeo} alt='insta' className='transition-transform w-6 transform-gpu hover:scale-105 focus:scale-105' />
                                                </a>
                                                <a href='https://www.linkedin.com/in/christian-saint-612352221/'>
                                                    <img src={Linkdin} alt='insta' className='transition-transform w-6 transform-gpu hover:scale-105 focus:scale-105' />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
                <p className='text-center lg:text-7xl font-bold md:text-[62px] text-[48px] text-white pt-24 pb-32'>
                    {pageName}
                </p>
            </div>
        </div>

    )
}

Header.propTypes = {
    pageName: PropTypes.string// Adjust the PropTypes as needed
};
