import image1 from "../../assets/Partners/1.png"
import image2 from "../../assets/Partners/2.png"
import image3 from "../../assets/Partners/3.png"
import image4 from "../../assets/Partners/4.png"
import image5 from "../../assets/Partners/5.png"
import image6 from "../../assets/Partners/6.png"
import image7 from "../../assets/Partners/7.png"
import image8 from "../../assets/Partners/8.png"
import image9 from "../../assets/Partners/9.png"
import image10 from "../../assets/Partners/10.jpg"
import image11 from "../../assets/Partners/11.png"
import image12 from "../../assets/Partners/12.jpg"
import image13 from "../../assets/Partners/13.png"
import image14 from "../../assets/Partners/14.png"
import image15 from "../../assets/Partners/15.png"


export default function Partners() {

    const imageDataPartner = [
        image1,
        image2,
        image3,
        image4,
        image5,
        image6,
        image7,
        image8,
        image9,
        image10,
        image11,
        image12,
        image13,
        image14,
        image15,
    ]

    return (
        <div className='flex justify-center bg-black'>
            <div className='lg:w-8/12 md:w-10/12 w-full py-16 md:px-0 px-4 text-center'>
                <h1 className='text-white font-bold'>
                    PARTNERS
                </h1>
                <p className='lg:text-[38px] md:text-[30px] text-[24px] text-white font-semibold pt-8 leading-9'>
                    Our innovation partners
                </p>
                <div className='grid lg:grid-cols-5 md:grid-cols-3 grid-cols-2 gap-5 py-12 pt-16'>
                    {imageDataPartner.map((item, index) => (
                        <div className='py-2 border bg-white border-gray-200 flex justify-center' key={index}>
                            <img src={item} className='w-44' />
                        </div>
                    ))}
                </div>
            </div></div>
    )
}
