
import { LandingLayout } from '../layouts'
import { SectionOneLanding,SectionTwoLanding ,SectionThreeLanding} from "../components"
import BackgroundImage from "../assets/svg/backgroundLanding.jpg"
import ScrollToTop from '../components/scrolltop'

export default function LifeInSirti() {
    return (
        <LandingLayout pageName="Building the Future">
            <ScrollToTop/>
            <SectionOneLanding />
            <div className='py-64 relative' style={{ backgroundImage: `url(${BackgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center', }}>
              <div className='absolute top-0 h-full bg-gradient-to-b from-white to-black'>
              </div>
            </div>
            <SectionTwoLanding/>
            {/* <SectionThreeLanding/> */}
        </LandingLayout>
    )
}
