import PropTypes from 'prop-types';
import { Header } from '../components'
import Footer from '../components/footer';


export default function MainLayout({ children, pageName, Banner }) {
    return (
        <div className='bg-white'>
            <Header pageName={pageName} BannerImage={Banner} />
            {children}
            <Footer/>
        </div>
    )
}
MainLayout.propTypes = {
    pageName: PropTypes.string,// Adjust the PropTypes as needed
    children: PropTypes.children,
    Banner:PropTypes.elementType
};
