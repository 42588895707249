
import { MainLayout } from '../layouts'
import { SectionOneLife, SectionTwoLife } from "../components"
import LifeImage from "../assets/Banners/Life.png"
import Life from "../assets/Life/LifeImage.jpg"
import Life2 from "../assets/Life/LifeImage2.jpg"
import ScrollToTop from '../components/scrolltop'

export default function LifeInSirti() {
  return (
    <MainLayout pageName="Life In Callcom" Banner={LifeImage}>
      <ScrollToTop/>
      <SectionOneLife />
      <div className='hidden xl:block relative'>
        <div className="w-[100%] h-[663px] absolute z-[9] mb-[580px]" style={{ background: "linear-gradient(rgb(255, 255, 255) 120px, rgba(255, 255, 255, 0) 80%)" }}>
        </div>
        <div className='h-[100vh] relative' style={{ backgroundImage: `url(${Life})`, backgroundSize: 'cover', backgroundPosition: 'center', }}>
          <div className='absolute top-0 h-full bg-gradient-to-b from-white to-black'>
          </div>
        </div>
      </div>

      <SectionTwoLife />

      <div className='hidden xl:block relative'>
        <div className="w-[100%] h-[663px] absolute z-[9] mb-[580px]" style={{ background: "linear-gradient(rgb(255, 255, 255) 120px, rgba(255, 255, 255, 0) 80%)" }}>
        </div>
        <div className='h-[100vh] relative' style={{ backgroundImage: `url(${Life2})`, backgroundSize: 'cover', backgroundPosition: 'center', }}>
          <div className='absolute top-0 h-full bg-gradient-to-b from-white to-black'>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}
