import { useState } from 'react';
import { CAlert } from '@coreui/react';
import 'react-toastify/dist/ReactToastify.css';

const GetInTouchWithUs = () => {
  const [formData, setFormData] = useState({
    contactName: '',
    contactEmail: '',
    contactPhone: '',
    service: '',
    message: '',
  });

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch('https://backend.callcomglobal.com/sendEmail', {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();

      if (result.success) {
        setFormData({
          contactName: "",
          contactEmail: "",
          contactPhone: "",
          service: "",
          message: "",
        });
        setShowAlert(true);
        setAlertType("success");
        setAlertMessage("Thank you! We will respond back within 1 business day.");
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      } else {
        setShowAlert(true);
        setAlertType("danger");
        setAlertMessage("Error sending Email");
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      }
    } catch (error) {
      setShowAlert(true);
      setAlertType("danger");
      setAlertMessage("Error Submitting Form");
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  };

  return (
    <>
      {showAlert && (
        <CAlert
          color={alertType}
          style={{
            position: "fixed",
            top: "50px",
            right: "10px",
            transition: "ease-in-out 5s",
            zIndex: "1000000000",
            borderRadius: "10px",
            padding: "15px",
            backgroundColor: `${alertType === 'success' ? '#2acfb3' : '#bf1b2c'}`,
            color: `${alertType === 'success' ? 'white' : 'black'}`
          }}
        >
          {alertMessage}
        </CAlert>
      )}

      <div className="bg-white  lg:px-20">
        <div className="container   flex flex-col px-5 py-20 items-center justify-between lg:px-8 lg:flex-row ">
          {/* Left Div */}
          <div className="w-full  lg:w-1/2 bg-white mb-4 lg:px-8 ">
            <h2 className="text-3xl md:text-4xl font-bold mb-4">Get in touch with us</h2>
            <p className="text-black font-light text-lg md:text-xl mt-10 mb-5">
              Do you want to get the best price from your telecommunications
              service provider? You’ve come to the right place. We act as a
              conduit between major service providers and businesses.
            </p>
            <hr className="border-neutral-200 my-8" />
            <form onSubmit={handleSubmit}>
              <div className="mb-2">
                <input
                  type="text"
                  name="contactName"
                  id="contactName"
                  value={formData.contactName}
                  onChange={handleChange}
                  required
                  placeholder="Contact name*"
                  className="mt-1 w-full p-2 border border-neutral-200 "
                />
              </div>
              <div className="mb-2">
                <input
                  type="text"
                  name="contactEmail"
                  id="contactEmail"
                  value={formData.contactEmail}
                  onChange={handleChange}
                  placeholder="Email*"
                  required
                  className="mt-1 w-full p-2 border border-neutral-200 "
                />
              </div>
              <div className="mb-2">
                <input
                  type="text"
                  name="contactPhone"
                  id="contactPhone"
                  value={formData.contactPhone}
                  onChange={handleChange}
                  placeholder="Phone Number*"
                  required
                  className="mt-1 w-full p-2 border border-neutral-200 "
                />
              </div>

              <div className="mb-2">
                <select
                  id="quote"
                  name="quote"
                  value={formData.quote}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full py-2 px-3 border border-neutral-200 bg-neutral-100 shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                  <option>Would you like a quote?</option>
                  <option>Yes</option>
                  <option>No</option>
                </select>
              </div>
              <div className="mb-2">
                <select
                  id="service"
                  name="service"
                  value={formData.service}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full py-2 px-3 border border-neutral-200 bg-neutral-100 shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                  <option>What service is this regarding?</option>
                  <option>Telco Infrastructure</option>
                  <option>Digital Solutions</option>
                  <option>Others</option>
                </select>
              </div>
              <div className="mb-4 ">
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  rows="4"
                  required
                  placeholder="Message"
                  className="mt-1 p-2 shadow-sm border border-neutral-200  focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm  "
                ></textarea>
              </div>
              <div className="text-center w-fit">
                <button type="submit" className="w-fit bg-black text-white text-xl font-medium border-2 border-black py-2 px-4 rounded-lg hover:bg-transparent hover:text-black">Send Inquiry</button>
              </div>
            </form>
          </div>
          {/* Right Div (Form) */}
          <div className="w-full lg:w-1/2 relative ">
            <div className="relative lg:absolute w-full lg:-bottom-[95px] xl:-bottom-[118px]">
              {/* <ProductsRange /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GetInTouchWithUs;
