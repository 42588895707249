

export default function PageComponetOne() {
  return (
    <div className='flex justify-center'>
      <div className='lg:w-8/12 md:w-10/12 w-full py-16 md:px-0 px-4'>
        <h1 className='text-heading font-bold'>
          Our Mission
        </h1>
        <p className='lg:text-[45px] md:text-[38px] text-[20px] text-black font-semibold pt-12'>
          We want to connect people and things in a sustainable and intelligent way…
        </p>
        <div className='w-24 bg-heading pb-0.5 mt-6'>

        </div>
        <div className='grid md:grid-cols-2 grid-cols-1 gap-16 pt-12'>
          <div className='text-black font-extralight text-lg md:text-xl'>
            <p>
              create digital highways that will connect us to the future; build strong and modern infrastructures that do not fear the passage of technological eras and serve as a legacy for the countries that host them.
              We want to assist our customers in focusing on their core business by handling the technological and procedural  </p>
          </div>
          <div className='text-black font-extralight text-lg md:text-xl'>
            <p>
              complexities associated with systems and plants.
              We are committed to working in a clean and transparent way, in full compliance with the rules, while also caring for the safety of our employees and the environment in which we operate, because the value we generate must last over time as a testimony to our passion for doing things well. </p>
          </div>

        </div>
      </div>
    </div>
  )
}
