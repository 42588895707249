
import { MainLayout } from '../layouts'
import { SectionOneInnovation ,SectionTwoInnovation,SectionThreeInnovation} from "../components"
import InnovationImage from "../assets/Banners/Innovation.png"
import ScrollToTop from '../components/scrolltop'

export default function Innovation() {
  return (
    <MainLayout pageName="Innovation" Banner={InnovationImage}>
      <ScrollToTop/>
      <SectionOneInnovation />
      <SectionTwoInnovation/>
      <SectionThreeInnovation/>
    </MainLayout>
  )
}
