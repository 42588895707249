
import { MainLayout } from '../layouts'
import { SectionOneSustain, SectionTwoSustain, SectionThreeSustain } from "../components"
import SustainImage from "../assets/Banners/Sustainbility.png"
import BackgroundImage from "../assets/SustainBanner.jpg"
import Sustain2 from "../assets/Sustain2.jpg"
import Sustain3 from "../assets/Sustain3.jpg"
import Sustain4 from "../assets/Sustain4.jpg"
import ScrollToTop from '../components/scrolltop'

export default function Sustainability() {
  return (
    <MainLayout pageName="Sustainability" Banner={SustainImage}>
      <ScrollToTop/>
      <SectionOneSustain />
      {/* <div style="width:100%;text-align:;height: 663px;background: ;margin-bottom:-580px;position:relative;z-index:9;">
      </div> */}
      <div className='hidden xl:block relative'>
        <div className="w-[100%] h-[663px] absolute z-[9] mb-[580px]" style={{ background: "linear-gradient(rgb(255, 255, 255) 120px, rgba(255, 255, 255, 0) 80%)" }}>
        </div>
        <div className='h-[100vh] relative' style={{ backgroundImage: `url(${BackgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center', }}>
          <div className='absolute top-0 h-full bg-gradient-to-b from-white to-black'>
          </div>
        </div>
      </div>

      <div className='flex flex-col lg:flex-row justify-between'>
        <div className='lg:basis-[50%]'>
          <img src={Sustain2} alt="" className='w-fit h-fit' />
        </div>
        <div className='lg:basis-[50%]'>
          <div className='flex justify-center'>
            <div className='w-full py-16 md:pb-20 lg:pb-64 md:px-0 px-4'>
              <h1 className='text-heading font-bold text-lg md:text-xl'>
                CALLCOM SUSTAINABILITY PROGRAM
              </h1>
              <p className='lg:text-4xl w-11/12 font-bold md:text-3xl text-2xl text-black pt-12'>
                Commitment to sustainability is an integral part of our strategy
              </p>
              <div className="w-[10%] h-2 my-10 bg-gray-700 rounded-full"></div>
              <div className="font-extralight mr-10 grid grid-cols-1 gap-10 text-black pt-8">
                <p className="text-xl md:text-2xl font-light text-black">
                  The Group has adopted a strategy focused on sustainable growth over time, valuing people, being sensitive to the social context and reducing direct and indirect environmental impact.
                </p>
                <p className="text-xl md:text-2xl font-light text-black">
                  To this end, the ‘Callcom Sustainability Programme’ was launched, a programme of commitments and actions aimed at improving economic, environmental and social sustainability performance, both in the short term and in the long term.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className='hidden xl:block relative'>
        <div className="w-[100%] h-[663px] absolute z-[9] mb-[580px]" style={{ background: "linear-gradient(rgb(255, 255, 255) 120px, rgba(255, 255, 255, 0) 80%)" }}>
        </div>
        <div className='h-[100vh] relative' style={{ backgroundImage: `url(${Sustain3})`, backgroundSize: 'cover', backgroundPosition: 'center', }}>
          <div className='absolute top-0 h-full bg-gradient-to-b from-white to-black'>
          </div>
        </div>
      </div>
      <SectionTwoSustain />
      
      {/* <SectionThreeSustain /> */}

      <div className='hidden xl:block relative'>
        <div className="w-[100%] h-[663px] absolute z-[9] mb-[580px]" style={{ background: "linear-gradient(rgb(255, 255, 255) 120px, rgba(255, 255, 255, 0) 80%)" }}>
        </div>
        <div className='h-[100vh] relative' style={{ backgroundImage: `url(${Sustain4})`, backgroundSize: 'cover', backgroundPosition: 'center', }}>
          <div className='absolute top-0 h-full bg-gradient-to-b from-white to-black'>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}
