import PropTypes from 'prop-types';
import HeaderLanding from '../components/headerLanding'
import Footer from '../components/footer';

export default function LandingLayout({ children, pageName }) {
    return (
        <div className='bg-white'>
            <HeaderLanding pageName={pageName} />
            {children}
            <Footer/>
        </div>
    )
}
LandingLayout.propTypes = {
    pageName: PropTypes.string,// Adjust the PropTypes as needed
    children: PropTypes.children
};
