
import Integerity from "../../assets/svg/theGroup/integerity.svg"
import center from "../../assets/svg/theGroup/canter.svg"
import Environmental from "../../assets/svg/theGroup/environmental.svg"
import Movement from "../../assets/svg/theGroup/movement.svg"
export default function comitments() {
    return (
        <div className='flex justify-center'>
            <div className='lg:w-8/12 md:w-10/12 w-full py-16 md:px-0 px-4'>
                <h1 className='text-heading font-bold'>
                    OUR COMMITMENT TO EXCELLENCE
                </h1>
                <p className='lg:text-[38px] md:text-[28px] text-[24px] text-black font-semibold pt-12 leading-9'>
                    Callcom Global is committed to excellence in processes aimed at continuous improvement, also through training, certifications and management systems
                </p>

                <div className='pt-12 grid md:grid-cols-2 grid-cols-1 gap-14'>
                    <div className='flex space-x-6'>
                        <img className='w-32' src={Integerity} />
                        <div className='space-y-5'>
                            <p className='text-[20px] font-extrabold text-black'>
                                INTEGRITY
                            </p>
                            <p className='text-black'>
                                Anti-corruption <br />
                                Security information
                            </p>
                        </div>
                    </div>
                    <div className='flex space-x-6'>
                        <img className='w-32' src={Environmental} />
                        <div className='space-y-5'>
                            <p className='text-[20px] font-extrabold text-black'>
                                ENVIRONMENTAL PROTECTION
                            </p>
                            <p className='text-black'>
                                Environmental Management <br />
                                Energy management
                            </p>
                        </div>
                    </div>
                    <div className='flex space-x-6'>
                        <img className='w-32' src={center} />
                        <div className='space-y-5'>
                            <p className='text-[20px] font-extrabold text-black'>
                                PEOPLE CENTRIC
                            </p>
                            <p className='text-black'>
                                H&S Management<br />
                                Social Accountability
                            </p>
                        </div>
                    </div>
                    <div className='flex space-x-6'>
                        <img className='w-32' src={Movement} />
                        <div className='space-y-5'>
                            <p className='text-[20px] font-extrabold text-black'>
                                PROCESS IMPROVEMENT
                            </p>
                            <p className='text-black'>

                                Quality Management <br />
                                IT Service Management
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
