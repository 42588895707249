
export default function SectionOne() {
    return (
        <div className='flex justify-center'>
            <div className='lg:w-8/12 md:w-10/12 w-full py-16 md:px-0 px-4'>
                <h1 className='text-heading font-bold'>
                Our Innovation
                </h1>
                <p className='lg:text-[42px] md:text-[28px] text-[24px] text-black font-semibold pt-12'>
                    We provide our clients with our experience and ability to innovate
                </p>
                <div className="font-extralight grid grid-cols-1 md:grid-cols-2 gap-10 text-black pt-8">
                    <p>
                        We manage innovation processes in a structured manner, analysing global megatrends, actively involving our corporate population in generating project ideas, and leveraging our ecosystem of start-ups and large technology vendors to devise innovative and sustainable solutions that create value over time.
                    </p>
                    <p>
                        Through the adoption of Open Innovation models, we provide our customers with our experience and ability to innovate with a view to designing, together, the solutions that will transform their business and make them more competitive.
                    </p>
                </div>
            </div>
        </div>
    )
}
